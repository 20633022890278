import { AxiosHttpClient } from 'api/axios'
import { ProofreadEventResponse } from 'api/partner/proofread_plans/proofread_event'
import { useRepruaDeleteApi, useRepruaIndexApi, useRepruaPatchApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { ProofreadApproverForm, ProofreadBulkApproveForm } from 'entities/proofread_approver'
import { getProofreadEventSearchQuery, ProofreadEvent, ProofreadEventForm, ProofreadEventFormKeys, ProofreadEventSearchForm } from 'entities/proofread_event'
import { pick } from 'lodash'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

/**
 * 設定
 */
export function useUpdateProofreadEventApi(form: Form<ProofreadEventForm>): ApiSet<ProofreadEventResponse> & { execute: () => void } {
  const api = useRepruaPatchApi<ProofreadEventResponse, ProofreadEventForm>(new AxiosHttpClient('user'), {
    initialResponse: { proofreadEvent: {} },
  })

  const execute = () => {
    const path = `proofread_events/${form.object.id}`
    api.execute(path, pick(form.object, ProofreadEventFormKeys))
  }

  return { ...api, execute }
}

/**
 * 計画
 */
export function usePlanProofreadEventApi(form: Form<ProofreadEventForm>): ApiSet<ProofreadEventResponse> & { execute: () => void } {
  const api = useRepruaPatchApi<ProofreadEventResponse, ProofreadEventForm>(new AxiosHttpClient('user'), {
    initialResponse: { proofreadEvent: {} },
  })

  const execute = () => {
    const path = `proofread_events/${form.object.id}/event_plan`
    api.execute(path, pick(form.object, ProofreadEventFormKeys))
  }

  return { ...api, execute }
}

/**
 * 承認
 */
export function usePatchProofreadEventApproveApi(
  form: Form<ProofreadApproverForm>,
): ApiSet<ProofreadEventResponse> & { execute: (state: 'approved' | 'rejected') => void } {
  const api = useRepruaPatchApi<ProofreadEventResponse, ProofreadApproverForm>(new AxiosHttpClient('user'), {
    initialResponse: { proofreadEvent: {} },
  })

  const execute = (state: 'approved' | 'rejected') => {
    const path = `proofread_events/${form.object.proofreadEventId}/approve`
    api.execute(path, { ...form.object, aasmState: state })
  }

  return { ...api, execute }
}

/**
 * 一括承認
 */
export function usePatchProofreadEventBulkApproveApi(
  form: Form<ProofreadBulkApproveForm>,
): ApiSet<BaseResponse> & { execute: (state: 'approved' | 'rejected') => void } {
  const api = useRepruaPatchApi<BaseResponse, ProofreadBulkApproveForm>(new AxiosHttpClient('user'), {
    initialResponse: {},
  })

  const execute = (state: 'approved' | 'rejected') => {
    const path = 'proofread_events/bulk_approve'
    api.execute(path, { ...form.object, aasmState: state })
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteProofreadEventApi(id: number): ApiSet<ProofreadEventResponse> & { execute: () => void } {
  const api = useRepruaDeleteApi<ProofreadEventResponse>(new AxiosHttpClient('user'), { initialResponse: { proofreadEvent: {} } })

  const execute = () => {
    const path = `proofread_events/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 一括削除
 */
export function useBulkDeleteProofreadEventApi(form: Form<ProofreadEventForm>): ApiSet<BaseResponse> & { execute: () => void } {
  // FIXME: racのuseDeleteApi は option params を受け付けていないため、patchで対応している
  const api = useRepruaPatchApi<BaseResponse, ProofreadEventForm>(new AxiosHttpClient('user'), {
    initialResponse: {},
  })

  const execute = () => {
    const path = 'proofread_events/bulk_delete'
    api.execute(path, {
      eventIds: form.object.eventIds,
      proofreadPlanId: form.object.proofreadPlanId
    })
  }

  return { ...api, execute }
}

/**
 * 一括作成
 */
export function useBulkCreateEventsApi(form: Form<ProofreadEventForm>): ApiSet<ProofreadEventResponse> & { execute: () => void } {
  const api = useRepruaPostApi<ProofreadEventResponse, ProofreadEventForm>(new AxiosHttpClient('user'), {
    initialResponse: { proofreadEvent: {} },
  })

  const execute = () => {
    const path = 'proofread_events/bulk_create'
    // PostAPI は Form を渡す仕様なので Pick できないが、POST時には不要な body は入り込みにくいため、Pick は不要
    api.execute(path, form)
  }

  return { ...api, execute }
}

export type ProofreadEventsResponse = PagingResponse & {
  proofreadEvents: ProofreadEvent[]
}

/**
 * ログイン中のユーザーが承認者になっている校正イベント
 */
export function useFetchProofreadEventsWithCurrentUserApprovalApi(
  searchForm: Form<ProofreadEventSearchForm>,
): IndexApiSet<ProofreadEventsResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<ProofreadEventsResponse, ProofreadEventSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { proofreadEvents: [], totalCount: 0 },
  })

  const { searchText, ...rest } = searchForm.object

  const execute = () => {
    const path = 'proofread_events/with_current_user_approvals'
    api.execute(path, { params: rest, searchQuery: getProofreadEventSearchQuery(searchText) })
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}

/**
 * 一括更新
 */
export function useBulkUpdateProofreadEventApi(form: Form<ProofreadEventForm>): ApiSet<BaseResponse> & { execute: () => void } {
  const api = useRepruaPatchApi<BaseResponse, ProofreadEventForm>(new AxiosHttpClient('user'), {
    initialResponse: {},
  })

  const execute = () => {
    const path = 'proofread_events/bulk_update'
    api.execute(path, pick(form.object, ProofreadEventFormKeys))
  }

  return { ...api, execute }
}
